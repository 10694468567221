<template>
  <form autocomplete="off" class="">
    <div class="row g-3 justify-content-start">
        <div v-if="!data.is_real" class="col-md-12">
          <label for="inputReviewerName">
              Reviewer's name <span class="text-danger small">*</span>
          </label>
          <input type="text" v-model="data.reviewer_name"
              :class="{ 'is-invalid': v$.data.reviewer_name.$error }"
              id="inputReviewerName" name="reviewer_name" class="form-control"
              placeholder="Enter name" />
          <div v-for="(item, index) in v$.data.reviewer_name.$errors" :key="index"
              class="invalid-feedback">
              <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div v-if="!data.is_real" class="col-12 mb-3">
          <vue-dropzone id="reviewPhotoDrop" ref="reviewPhotoDrop" 
            :use-custom-slot="true" :maxFiles="1">
              <div class="needsclick">
                <span class="bx bx-cloud-upload fs-1"></span>
                <h6> {{editMode? 'Update photo':'Add photo'}}</h6>
                <span class="text-primary small">
                  <em>Reviewer's photo (optional)</em>
                </span>
            </div>
          </vue-dropzone>
        </div>
        <div v-if="!data.is_real" class="col-12">
          <label class="form-label">
            Review Date <span class="text-danger">*</span>
          </label>
          <flat-pickr
            v-model="data.reviewed_at"
            :config="{wrap: true, 
              altFormat: 'M j, Y',
              altInput: true,
              dateFormat: 'Y-m-d',
            }"
            placeholder="Select date"
            class="form-control flatpickr-input"
            :class="{ 'is-invalid': v$.data.reviewed_at.$error }"
          ></flat-pickr>
          <div v-for="(item, index) in v$.data.reviewed_at.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div v-if="!data.is_real" class="col-md-12">
          <div class="form-group">
            <label>Country</label>
            <multiselect 
              mode="single"
              searchable
              valueProp="id"
              trackBy="name" label="name"
              :options="countries" 
              v-model="data.country_id" placeholder="--select--">
            </multiselect>
          </div>
        </div>
        <div v-if="!data.is_real" class="col-12">
          <label class="form-label">Choose Product</label>
          <select class="form-select" v-model="data.product_id" name="product"
          :class="{ 'is-invalid': v$.data.product_id.$error }" placeholder="-- select product--">
            <option disabled :value="null" key="-1">-- Choose product --</option>
            <option :value="product.id" v-for="(product, index) in productList" :key="index">{{product.name}}</option>
          </select>
          <div v-for="(item, index) in v$.data.product_id.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div class="col-12">
          <label class="form-label">Write review</label>
          <textarea v-model="data.content" class="form-control"
          :class="{ 'is-invalid': v$.data.content.$error }"
          rows="6" placeholder="Write review" name="description"></textarea>
          <div v-for="(item, index) in v$.data.content.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div class="col-12">
          <div>
            <star-rating 
              v-model:rating="data.rating"
              :star-size="30"></star-rating>
          </div>
          <div v-for="(item, index) in v$.data.rating.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div class="col-12"><hr ></div>
        <div class="col-12">
          <label class="form-label">Write response (optional)</label>
          <textarea v-model="data.response" class="form-control"
          rows="6" placeholder="Write review" name="description"></textarea>
        </div>
        <div class="col-12">
          <label class="form-label">
            Response Date
          </label>
          <flat-pickr
            v-model="data.responded_at"
            :config="{wrap: true, 
              altFormat: 'M j, Y',
              altInput: true,
              dateFormat: 'Y-m-d',
            }"
            placeholder="Select date"
            class="form-control flatpickr-input"
          ></flat-pickr>
        </div>
        <div class="col-12">
          <div class="d-flex mt-3 justify-content-end">
            <b-button variant="dark" 
            @click.prevent="editMode? update() : create()"
              type="button">{{editMode? 'Update':'Submit'}}</b-button>
          </div>
        </div>
    </div>
  </form>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import VueDropzone from '@/components/VueDropzone.vue';
import flatPickr from "vue-flatpickr-component";
import Multiselect from '@vueform/multiselect'
import StarRating from 'vue-star-rating'

export default{
  setup() {
    return { v$: useVuelidate() };
  },
  components:{
    flatPickr,
    VueDropzone,
    Multiselect,
    StarRating
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        reviewer_name: "",
        content: "",
        reviewed_at: null,
        country_id: null,
        rating: null,
        product_id: null,
        is_real: false,
        response: "",
        responded_at: null,
      },
      mirrorData: null
    }
  },
  validations() {
    return {
      data:{
        content: { required: required},            
        rating: { required: required},            
        product_id: {required: required},
        reviewer_name: {
          requiredIf: requiredIf(!this.data.is_real),
        },          
        country_id: {
          requiredIf: requiredIf(!this.data.is_real),
        },  
        reviewed_at: {
          requiredIf: requiredIf(!this.data.is_real),
        }          
      }
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    productList() {
      return this.$store.state.productList.products.map((pro) => {
        return { id:pro.id, name:pro.name} });
    },
  },
  methods: {
    create() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){ formData.append('reviewer_image_file', this.collectFiles()[0]) }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/reviews/create`, formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("reviewList/ADD_REVIEW", response.data.data)
            this.resetForm();
          }
        })
      })
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){ formData.append('reviewer_image_file', this.collectFiles()[0]);}
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/reviews/${this.editItem.id}/update`,formData)
          .then((response) => {
              this.$store.dispatch("changeLoaderValue", false)
              if(response.data.success){
                this.$store.commit("reviewList/UPDATE_REVIEW", response.data.data)
                this.resetForm()
                this.$emit("closeMe")
              }
          })
        
        })
    },
    collectFiles(){
        var arrafile = [];
        if(this.$refs.reviewPhotoDrop){
            arrafile = [...this.$refs.reviewPhotoDrop.getAcceptedFiles()];
        }
        return arrafile
    },
    resetForm() {
      this.data = {...this.mirrorData}
      if(this.$refs.reviewPhotoDrop){
        this.$refs.reviewPhotoDrop.removeAllFiles();
      }
      this.v$.$reset()
    },
    patchUpdatables(){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
      this.data.reviewed_at = this.data.reviewed_at ? new Date(this.data.reviewed_at) : null;
      this.data.responded_at = this.data.responded_at ? new Date(this.data.responded_at) : null;
    }
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.patchUpdatables()
    }
  }
}
</script>
