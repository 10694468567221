<template>
<div>
    <div class="row">
        <div class="col-12">
            <h5 class="mb-3">Reviews</h5>
            <div class="row mb-3 gy-2">
                <div class="col-sm-12 col-md-7 d-sm-flex">
                    <div class="mb-2 mb-sm-0">
                        <button class="me-2 btn text-white btn-primary" @click="popupModalShow = true">
                            <i class="bx bx-plus align-baseline"></i> <span class=""> Add </span>
                        </button>
                        <span class="">
                            <label class="d-inline-flex mb-0 align-items-center">
                                <b-form-select class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                            </label>
                        </span>
                    </div>
                    <div class="ms-sm-2">
                        <select @change="fetchItems()" id="status-select" v-model="sordBy"  class="form-select">
                            <option disabled selected>-Sort By-</option>
                            <option value='1'>Original</option>
                            <option value='0'>Created</option>
                            <option value='all'>Show all</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12 col-md-5">
                    <div class="input-group mb-0">
                        <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
                        <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <is-loading v-if="isLoading" />
    <div v-else>
        <div class="row">
            <div class="col-md-6" v-for="(review, index) in reviews" :key="index">
                <review-card :item="review">
                    <template v-slot:actions>
                        <div class="dropdown dropstart no-arrow">
                            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bx bx-dots-vertical-rounded"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li v-if="review.is_real">
                                    <router-link  class="dropdown-item" :to="'/orders/'+review.order_id">View order</router-link>
                                </li>
                                <li>
                                    <button @click.prevent="initEdit(review.id)" class="dropdown-item" type="button"> Edit</button>
                                </li>
                                <li>
                                    <button @click.prevent="deleteItem(review.id)" class="dropdown-item" type="button"> Delete</button>
                                </li>
                            </ul>
                        </div>
                    </template>
                </review-card>
            </div>
        </div>
        <div class="mb-4">
            <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
            :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update review':'Create review'"
      title-class="fs-6" @hide="initializeAll()">
        <review-form v-if="popupModalShow" @closeMe="initializeAll()"
        :editMode="editMode" :editItem="editItem" />
    </b-modal>
</div>
</template>

<script>
import ReviewCard from "@/components/cards/ReviewCard.vue"
import ReviewForm from "@/components/forms/ReviewForm.vue"
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
    components: {
        ReviewForm, ReviewCard,
        IsLoading,
    },
    data() {
        return {
            isLoading: true,
            sordBy: "all",
            searchQuery:"",
            popupModalShow: false,
            editMode: false,
            editItem: null,            
            pageOptions: [10, 25, 50, 100],
        };
    },
    computed: {
        pageData(){
            return this.$store.state.reviewList.pageData
        },
        reviews() {
            return this.pageData.data.map((r) =>{
                return  {
                    id: r.id,
                    rating: r.rating,
                    is_real: r.is_real,
                    product_id: r.product_id,
                    order_id: r.order_id,
                    content: r.content,
                    product: r.product,
                    user: r.is_real 
                        ? { name: r.user.first_name+' '+r.user.last_name, image: r.user.image} 
                        : { name: r.reviewer_name, image: r.reviewer_image},
                    country: r.is_real 
                        ? r.user.country 
                        : r.country,
                    reviewed_at: r.is_real 
                        ? r.created_at
                        : r.reviewed_at
                }
            })
        },
        currentPage: {
            get() {
                return this.pageData.current_page
            },
            set(val) {
                if(!this.pageData.current_page || this.pageData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.pageData.per_page || 10
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods: {
        updateSearchQuery: _.debounce(function(string) {
            this.fetchItems()
        }, 2000),
        fetchItems(page, per_page = null){
            let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
            if(this.sordBy != 'all'){ payload.is_real = this.sordBy }
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("reviewList/fetchReviews", payload)
            .then(response => this.isLoading = false)
        },
        initEdit(itemId){
            this.editMode = true;
            this.editItem = {...this.pageData.data.find((i) => i.id == itemId )}
            this.popupModalShow = true  
        },
        deleteItem(itemId){
            this.confirmDelete({
                text: "You are about to delete the selected item!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.delete(`/reviews/${itemId}/delete`)
                    .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit(`reviewList/DELETE_REVIEW`, itemId)
                        }
                    })
                }
            });
        },
        initializeAll(){
            this.editMode = false;
            this.editItem = null;
            this.popupModalShow = false;
        },
    },
    created() {
        this.fetchItems()
        this.$store.dispatch("productList/fetchProducts")
    }
};
</script>


